import React, { useState } from "react";
import "../../css/forms/AdminInvoiceForm.css";

const AdminInvoiceForm = () => {
  // CUSTOMER fields
  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  // BILL TO fields
  const [billToName, setBillToName] = useState("");
  const [billToAddress, setBillToAddress] = useState("");
  const [billToPhone, setBillToPhone] = useState("");

  // SHIP INFO fields
  const [shipName, setShipName] = useState("");
  const [oceanVessel, setOceanVessel] = useState("");
  const [portOfLoading, setPortOfLoading] = useState("");

  // BOOKING & DISCHARGE fields
  const [bookingRef, setBookingRef] = useState("");
  const [portOfDischarge, setPortOfDischarge] = useState("");

  // CONSIGNEE fields
  const [consigneeName, setConsigneeName] = useState("");
  const [consigneeAddress, setConsigneeAddress] = useState("");
  const [consigneePhone, setConsigneePhone] = useState("");
  const [consigneeEmail, setConsigneeEmail] = useState("");

  // NOTIFY PARTIES fields
  const [notifyName, setNotifyName] = useState("");
  const [notifyAddress, setNotifyAddress] = useState("");
  const [notifyPhone, setNotifyPhone] = useState("");
  const [notifyEmail, setNotifyEmail] = useState("");

  // IMPORTER fields
  const [importerName, setImporterName] = useState("");
  const [importerAddress, setImporterAddress] = useState("");
  const [importerPhone, setImporterPhone] = useState("");
  const [importerEmail, setImporterEmail] = useState("");

  // Additional invoice fields
  const [tax, setTax] = useState("");
  const [notes, setNotes] = useState("");
  const [lineItems, setLineItems] = useState([
    {
      refNo: "",
      makerCarName: "",
      chassisNo: "",
      goodsDescription: "",
      cc: "",
      door: "",
      seat: "",
      shift: "",
      unitPrice: 0,
      freight: 0,
      insurance: 0,
      amount: 0,
    },
  ]);

  // Handle changes for line item fields.
  const handleLineItemChange = (index, field, value) => {
    const items = [...lineItems];
    items[index][field] = value;
    // Recalculate amount when pricing fields change.
    if (["unitPrice", "freight", "insurance"].includes(field)) {
      const unitPrice = parseFloat(items[index].unitPrice) || 0;
      const freight = parseFloat(items[index].freight) || 0;
      const insurance = parseFloat(items[index].insurance) || 0;
      items[index].amount = (unitPrice + freight + insurance).toFixed(2);
    }
    setLineItems(items);
  };

  // Add a new line item row.
  const addLineItem = () => {
    setLineItems([
      ...lineItems,
      {
        refNo: "",
        makerCarName: "",
        chassisNo: "",
        goodsDescription: "",
        cc: "",
        door: "",
        seat: "",
        shift: "",
        unitPrice: 0,
        freight: 0,
        insurance: 0,
        amount: 0,
      },
    ]);
  };

  // Remove a specific line item row.
  const removeLineItem = (index) => {
    setLineItems(lineItems.filter((_, i) => i !== index));
  };

  // Handle form submission.
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      customer: {
        name: customerName,
        address: customerAddress,
        phone: customerPhone,
      },
      billTo: { name: billToName, address: billToAddress, phone: billToPhone },
      shipInfo: { shipName, oceanVessel, portOfLoading },
      bookingDischarge: { bookingRef, portOfDischarge },
      consignee: {
        name: consigneeName,
        address: consigneeAddress,
        phone: consigneePhone,
        email: consigneeEmail,
      },
      notifyParties: {
        name: notifyName,
        address: notifyAddress,
        phone: notifyPhone,
        email: notifyEmail,
      },
      importer: {
        name: importerName,
        address: importerAddress,
        phone: importerPhone,
        email: importerEmail,
      },
      tax,
      notes,
      lineItems,
    };
    console.log("Form Data:", formData);
    alert("Invoice data submitted! Check console for details.");
  };

  return (
    <form className="admin-invoice-container" onSubmit={handleSubmit}>
      <h1>Invoice Data Entry</h1>

      <div className="fieldset-container">
        {/* CUSTOMER */}
        <fieldset className="fieldset">
          <legend>CUSTOMER</legend>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              value={customerAddress}
              onChange={(e) => setCustomerAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone:</label>
            <input
              type="text"
              value={customerPhone}
              onChange={(e) => setCustomerPhone(e.target.value)}
              required
            />
          </div>
        </fieldset>

        {/* BILL TO */}
        <fieldset className="fieldset">
          <legend>BILL TO</legend>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={billToName}
              onChange={(e) => setBillToName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              value={billToAddress}
              onChange={(e) => setBillToAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone:</label>
            <input
              type="text"
              value={billToPhone}
              onChange={(e) => setBillToPhone(e.target.value)}
              required
            />
          </div>
        </fieldset>

        {/* SHIP INFO */}
        <fieldset className="fieldset">
          <legend>SHIP INFO</legend>
          <div className="form-group">
            <label>Ship Name:</label>
            <input
              type="text"
              value={shipName}
              onChange={(e) => setShipName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Ocean Vessel:</label>
            <input
              type="text"
              value={oceanVessel}
              onChange={(e) => setOceanVessel(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Port of Loading:</label>
            <input
              type="text"
              value={portOfLoading}
              onChange={(e) => setPortOfLoading(e.target.value)}
              required
            />
          </div>
        </fieldset>

        {/* BOOKING & DISCHARGE */}
        <fieldset className="fieldset">
          <legend>BOOKING & DISCHARGE</legend>
          <div className="form-group">
            <label>Booking Ref:</label>
            <input
              type="text"
              value={bookingRef}
              onChange={(e) => setBookingRef(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Port of Discharge:</label>
            <input
              type="text"
              value={portOfDischarge}
              onChange={(e) => setPortOfDischarge(e.target.value)}
              required
            />
          </div>
        </fieldset>

        {/* CONSIGNEE */}
        <fieldset className="fieldset">
          <legend>CONSIGNEE</legend>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={consigneeName}
              onChange={(e) => setConsigneeName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              value={consigneeAddress}
              onChange={(e) => setConsigneeAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone:</label>
            <input
              type="text"
              value={consigneePhone}
              onChange={(e) => setConsigneePhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={consigneeEmail}
              onChange={(e) => setConsigneeEmail(e.target.value)}
              required
            />
          </div>
        </fieldset>

        {/* NOTIFY PARTIES */}
        <fieldset className="fieldset">
          <legend>NOTIFY PARTIES</legend>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={notifyName}
              onChange={(e) => setNotifyName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              value={notifyAddress}
              onChange={(e) => setNotifyAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone:</label>
            <input
              type="text"
              value={notifyPhone}
              onChange={(e) => setNotifyPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={notifyEmail}
              onChange={(e) => setNotifyEmail(e.target.value)}
              required
            />
          </div>
        </fieldset>

        {/* IMPORTER */}
        <fieldset className="fieldset">
          <legend>IMPORTER - IF OTHER THAN CONSIGNEE</legend>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={importerName}
              onChange={(e) => setImporterName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              value={importerAddress}
              onChange={(e) => setImporterAddress(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Phone:</label>
            <input
              type="text"
              value={importerPhone}
              onChange={(e) => setImporterPhone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={importerEmail}
              onChange={(e) => setImporterEmail(e.target.value)}
            />
          </div>
        </fieldset>
      </div>

      <h2>Line Items</h2>

      <div className="line-items-split-3">
        {/* Vehicle Identification */}
        <table className="line-items-table id-table">
          <thead>
            <tr>
              <th>Ref No.</th>
              <th>Maker/Car Name</th>
              <th>Chassis No</th>
            </tr>
          </thead>
          <tbody>
            {lineItems.map((item, index) => (
              <tr key={`id-${index}`}>
                <td>
                  <input
                    type="text"
                    value={item.refNo}
                    onChange={(e) =>
                      handleLineItemChange(index, "refNo", e.target.value)
                    }
                    placeholder="Ref No."
                    required
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item.makerCarName}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "makerCarName",
                        e.target.value,
                      )
                    }
                    placeholder="Maker/Car Name"
                    required
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item.chassisNo}
                    onChange={(e) =>
                      handleLineItemChange(index, "chassisNo", e.target.value)
                    }
                    placeholder="Chassis No"
                    required
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Vehicle Specs */}
        <table className="line-items-table specs-table">
          <thead>
            <tr>
              <th>Description</th>
              <th>CC</th>
              <th>Door</th>
              <th>Seat</th>
              <th>Shift</th>
            </tr>
          </thead>
          <tbody>
            {lineItems.map((item, index) => (
              <tr key={`specs-${index}`}>
                <td>
                  <input
                    type="text"
                    value={item.goodsDescription}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "goodsDescription",
                        e.target.value,
                      )
                    }
                    placeholder="Description"
                    required
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item.cc}
                    onChange={(e) =>
                      handleLineItemChange(index, "cc", e.target.value)
                    }
                    placeholder="CC"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item.door}
                    onChange={(e) =>
                      handleLineItemChange(index, "door", e.target.value)
                    }
                    placeholder="Door"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item.seat}
                    onChange={(e) =>
                      handleLineItemChange(index, "seat", e.target.value)
                    }
                    placeholder="Seat"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item.shift}
                    onChange={(e) =>
                      handleLineItemChange(index, "shift", e.target.value)
                    }
                    placeholder="Shift"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pricing Table */}
        <table className="line-items-table pricing-table">
          <thead>
            <tr>
              <th>Unit Price</th>
              <th>Freight</th>
              <th>Insurance</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {lineItems.map((item, index) => (
              <tr key={`pricing-${index}`}>
                <td>
                  <input
                    type="number"
                    value={item.unitPrice}
                    onChange={(e) =>
                      handleLineItemChange(index, "unitPrice", e.target.value)
                    }
                    step="0.01"
                    min="0"
                    required
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.freight}
                    onChange={(e) =>
                      handleLineItemChange(index, "freight", e.target.value)
                    }
                    step="0.01"
                    min="0"
                    required
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.insurance}
                    onChange={(e) =>
                      handleLineItemChange(index, "insurance", e.target.value)
                    }
                    step="0.01"
                    min="0"
                    required
                  />
                </td>
                <td>
                  <input type="number" value={item.amount} readOnly />
                </td>
                <td>
                  <button type="button" onClick={() => removeLineItem(index)}>
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button type="button" className="add-btn" onClick={addLineItem}>
        Add Item
      </button>

      <div className="form-group">
        <label>Tax (%):</label>
        <input
          type="number"
          value={tax}
          onChange={(e) => setTax(e.target.value)}
          step="0.01"
          placeholder="e.g., 7.5"
        />
      </div>

      <div className="form-group">
        <label>Additional Notes:</label>
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Enter any additional information here"
          rows="3"
        ></textarea>
      </div>

      <button type="submit" className="submit-btn">
        Generate Invoice
      </button>
    </form>
  );
};

export default AdminInvoiceForm;
